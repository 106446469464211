import { su2cTheme, crukTheme, ThemeType } from "@cruk/cruk-react-components";
import { RestrictionCode } from "../data/researchAreas";
import type { Option } from "../helpers/types/shared";
import headerConfig from "./headers";

export type IntroBannerType = {
  header?: string;
  copy?: string | false;
  giftaid?: boolean;
};

export type FormComponentsType = {
  donationAmount: {
    nudges?: Array<number>;
    regularNudges?: Array<number>;
  };
  donationMotivation?: boolean;
  donationDestination?: boolean;
  restriction?: RestrictionCode;
  header: {
    siteSlogan?: string;
    logoUrl: string;
    logoAlt: string;
    logoTitle: string;
    logoSrc: string;
  };
  introBanner?: IntroBannerType;
};

export type AdminType = {
  title: string;
  formID: string;
  product: {
    single?: string;
    collection?: string;
    regular?: string;
  };
  source: {
    single?: string;
    collection?: string;
    regular?: string;
  };
  merchantId?: "SU2CSingleDonation" | "CRUKSingleDonation";
  emailType: EmailType;
};

enum Email {
  CRUK = "cruk_pws_single_donation",
  CRUK_REGULAR = "cruk_pws_regular_donation",
  SU2C = "su2c_pws_single_donation",
  BMF = "bmf_pws_single_donation",
  RFL = "rfl_pws_single_donation",
}

// TODO Is there a way to make use of conditional types to ensure
//  single/regular email type is only required when single/regular is supported
type EmailType = {
  single?: Email;
  regular?: Email;
};

type FormConfigType = {
  [name: string]: {
    admin: AdminType;
    brand: string;
    themeName: string;
    theme: ThemeType;
    components: FormComponentsType;
  };
};

export type PaymentConfigType = {
  [name: string]: {
    admin: AdminType;
    themeName: string;
    components: FormComponentsType;
  };
};

export const config: FormConfigType = {
  "bank-old-chums": {
    admin: {
      title: "Bank Old Chums | Cancer Research UK",
      formID: "ONDON000026",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "18M1P1001",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [6, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        copy: "Thank you for being part of Bank Old Chums network and donating to Cancer Research UK.",
      },
    },
  },
  "bobby-moore-fund": {
    admin: {
      title: "Bobby Moore Fund | Cancer Research UK",
      formID: "ONDON000009",
      product: {
        single: "BMFDON",
        collection: "BMFDON",
      },
      source: {
        single: "N25P1I",
        collection: "N25P1I",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.BMF,
      },
    },
    brand: "BMF",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [20, 50, 100],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.BMF,
    },
  },
  "business-beats-cancer-guernsey": {
    admin: {
      title: "Business Beats Cancer Guernsey | Cancer Research UK",
      formID: "ONDON000058",
      product: {
        single: "GOFDON",
        collection: "GOFDON",
      },
      source: {
        single: "BBCRES066",
        collection: "BBCRES066",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {},
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        copy: "The Business Beats Cancer Guernsey board aim to bring the business community of Guernsey together to beat cancer sooner, by supporting Cancer Research UK Guernsey Branch and the Guernsey Society for Cancer Relief. Cancer Research UK are the largest single funder of cancer research globally and thanks to generous supporters, the charity has helped double cancer survival in the last 40 years. Businesses can play a significant role in supporting such worthy causes and the organising Board, would like to thank you for your contribution which is restricted to research in Southampton where many islands will go for local treatment, with cost support provided by the Guernsey Society for Cancer Relief. Together we will beat cancer.",
      },
    },
  },
  "donate-in-memory": {
    admin: {
      title: "Donate in memory | Cancer Research UK",
      formID: "ONDON000006",
      product: {
        single: "DIMONL",
        collection: "DIMONL",
      },
      source: {
        single: "SCZODDIM01",
        collection: "UKIM002",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [20, 80, 150],
      },
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
    },
  },
  "make-your-donation": {
    admin: {
      title: "Make your donation | Cancer Research UK",
      formID: "ONDON000033",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "24MDDB008",
        collection: "UKSF003",
        regular: "24MDDB005",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [10, 20, 30],
        regularNudges: [3, 5, 10],
      },
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
    },
  },
  "make-your-donation-today": {
    admin: {
      title: "Make your donation today | Cancer Research UK",
      formID: "ONDON000034",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "24MDDB009",
        collection: "UKSF003",
        regular: "24MDDB006",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [10, 20, 30],
        regularNudges: [3, 5, 10],
      },
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
    },
  },
  "more-research-less-cancer": {
    admin: {
      title: "More Research, Less Cancer | Cancer Research UK",
      formID: "ONDON000046",
      product: {
        single: "LGIDON",
        collection: "LGIDON",
      },
      source: {
        single: "SCZMGAPUNSTI",
        collection: "SCZMGAPUNSTI",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [400, 700, 1900],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "More Research, Less Cancer",
        copy: "We stand on the brink of discoveries that will transform how we understand and overcome cancer and other diseases. But we need to go further, faster.",
      },
    },
  },
  "donate-today": {
    admin: {
      title: "Donate today | Cancer Research UK",
      formID: "ONDON000027",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "24MOW9004",
        collection: "UKSF003",
        regular: "24MOW9003",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {},
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
    },
  },
  "donating-monthly": {
    admin: {
      title: "Donating monthly | Cancer Research UK",
      formID: "ONDON000057",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "23MDDB005",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [4, 6, 10],
      },
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
      introBanner: {
        header: "You are doing something amazing.",
        copy: "Your donation will drive life-saving research.",
      },
    },
  },
  "donating-today": {
    admin: {
      title: "Donating today | Cancer Research UK",
      formID: "ONDON000066",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "24MDDC007",
        collection: "UKSF003",
        regular: "24MDDC005",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [3, 5, 10],
        nudges: [10, 20, 30],
      },
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
      introBanner: {
        header: "You are doing something amazing.",
        copy: "Your donation will drive life-saving research.",
      },
    },
  },
  donating: {
    admin: {
      title: "Donating | Cancer Research UK",
      formID: "ONDON000067",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "24MDDC006",
        collection: "UKSF003",
        regular: "24MDDC004",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [3, 5, 10],
        nudges: [10, 20, 30],
      },
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
      introBanner: {
        header: "You are doing something amazing.",
        copy: "Your donation will drive life-saving research.",
      },
    },
  },
  donate: {
    admin: {
      title: "Donate | Cancer Research UK",
      formID: "ONDON000068",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "24MDDB010",
        collection: "UKSF003",
        regular: "24MDDB007",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [3, 5, 10],
        nudges: [10, 20, 30],
      },
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
      introBanner: {
        header: "You are doing something amazing.",
        copy: "Your donation will drive life-saving research.",
      },
    },
  },
  "festive-gift": {
    admin: {
      title: "Festive gift | Cancer Research UK",
      formID: "ONDON000050",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "24DJD7002",
        collection: "UKSF003",
        regular: "24DJD7001",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {},
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
      introBanner: {
        header: "You are doing something amazing.",
        copy: "Your donation will drive life-saving research.",
      },
    },
  },
  "festive-season": {
    admin: {
      title: "Festive season | Cancer Research UK",
      formID: "ONDON000055",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "24DJS9002",
        collection: "UKSF003",
        regular: "24DJS9001",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [5, 10, 20],
        regularNudges: [6, 15, 30],
      },
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
      introBanner: {
        header: "You are doing something amazing.",
        copy: "Your donation will drive life-saving research.",
      },
    },
  },
  "francis-crick-institute": {
    admin: {
      title: "Francis Crick Institute | Cancer Research UK",
      formID: "ONDON000010",
      product: {
        single: "LGIDON",
        collection: "LGIDON",
      },
      source: {
        single: "SCZMGAPCRICK",
        collection: "SCZMGAPCRICK",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [50, 100, 200],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header:
          "Your donation to Cancer Research UK will support the Francis Crick Institute’s pioneering work.",
        copy: "Cancer Research UK works in partnership with the Crick to attract and secure philanthropic support for the Crick’s pioneering work. The Crick is one of the funding priorities of Cancer Research UK’s global ‘More Research, Less Cancer’ campaign. For this arrangement to be possible, Cancer Research UK and the Crick operate as joint controllers of personal data. This involves jointly determining the purpose and means of processing information about donors and prospective donors, including sharing the information with each other and undertaking due diligence checks. For more information, see Cancer Research UK’s Privacy Policy at [https://www.cancerresearchuk.org/privacy-statement].",
      },
    },
  },
  "free-site-fundraiser-1": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000075",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1101",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "free-site-fundraiser-2": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000076",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1102",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "free-site-fundraiser-3": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000077",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1103",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "free-site-fundraiser-4": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000078",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1104",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "free-site-fundraiser-5": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000079",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1105",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-1": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000012",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1001",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-2": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000013",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1002",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-3": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000014",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1003",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-4": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000015",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1004",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-5": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000016",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1005",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-6": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000017",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1006",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-7": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000018",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1007",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-8": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000019",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1008",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-9": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000020",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1009",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-10": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000021",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1010",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-11": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000022",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1011",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-12": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000023",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1012",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-13": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000025",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1013",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-14": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000035",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1014",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-15": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000036",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1015",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-16": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000040",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1016",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-17": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000041",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1017",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-18": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000042",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1018",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-19": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000051",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1019",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-20": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000052",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1020",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-21": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000053",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1021",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-22": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000054",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1022",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-23": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000062",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1023",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-24": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000063",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1024",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "fundraiser-25": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000064",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25DFP1025",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  donation: {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000043",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "24MVF1029",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "You are doing something amazing.",
        copy: "Your donation will drive life-saving research.",
      },
    },
  },
  "fund-research-today": {
    admin: {
      title: "Fund research today | Cancer Research UK",
      formID: "ONDON000038",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "23MSPA002",
        collection: "UKSF003",
        regular: "23MSPA003",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [10, 20, 30],
        regularNudges: [3, 5, 10],
      },
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
    },
  },
  "get-started": {
    admin: {
      title: "Get started | Cancer Research UK",
      formID: "ONDON000049",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "24DJD7004",
        collection: "UKSF003",
        regular: "24DJD7003",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {},
      donationMotivation: false,
      donationDestination: true,
      header: headerConfig.CRUK,
      introBanner: {
        header: "You are doing something amazing.",
        copy: "Thank you again for your support of Cancer Research UK's life-saving work.",
      },
    },
  },
  "give-today": {
    admin: {
      title: "Give today | Cancer Research UK",
      formID: "ONDON000028",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "24MTI9002",
        collection: "UKSF003",
        regular: "24MTI9001",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [10, 20, 30],
        regularNudges: [6, 15, 30],
      },
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
    },
  },
  "race-for-life": {
    admin: {
      title: "Race for Life | Cancer Research UK",
      formID: "ONDON000024",
      product: {
        single: "R4LDON",
        collection: "R4LDON",
      },
      source: {
        single: "N25L00",
        collection: "N25L00",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.RFL,
      },
    },
    brand: "RFL",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [20, 80, 150],
      },
      donationMotivation: false,
      donationDestination: true,
      header: headerConfig.RFL,
      introBanner: {
        header: "Donate right now to Race for Life to help beat cancer",
        copy: "Cancer Research UK is working every day to find new and improved ways to prevent, diagnose and treat cancer. Our ambition is to accelerate progress so that 3 in 4 people survive cancer by 2034. We promise to spend your donations wisely.",
      },
    },
  },
  "make-a-donation": {
    admin: {
      title: "Make a donation | Cancer Research UK",
      formID: "ONDON000032",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "23MDDA004",
        collection: "UKSF003",
        regular: "23MDDA003",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [10, 20, 30],
        regularNudges: [3, 5, 10],
      },
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
    },
  },
  "my-donation": {
    admin: {
      title: "My donation | Cancer Research UK",
      formID: "ONDON000030",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "24MOW9006",
        collection: "UKSF003",
        regular: "24MOW9005",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {},
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
    },
  },
  "ngl-donations": {
    admin: {
      title: "NGL donations | Cancer Research UK",
      formID: "ONDON000008",
      product: {
        single: "GOFDON",
        collection: "GOFDON",
      },
      source: {
        single: "UKSF001",
        collection: "UKSF001",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {},
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "CRUK Charity Golf Day 2024",
        copy: "Thank you for supporting this year’s CRUK Charity Golf Day. Please complete your donation and enter your details in the space below. If you have any questions, please do not hesitate to contact Joanne at Cancer Research UK on [0203 469 6292] or [joanne.wood@cancer.org.uk].",
      },
    },
  },
  restart: {
    admin: {
      title: "Restart | Cancer Research UK",
      formID: "ONDON000039",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "00MOL0200",
        collection: "UKSF003",
        regular: "00MOL0201",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [10, 20, 30],
        regularNudges: [2, 5, 8],
      },
      donationMotivation: false,
      donationDestination: true,
      header: headerConfig.CRUK,
      introBanner: {
        header: "You are doing something amazing.",
        copy: "Thank you again for your support of Cancer Research UK's life-saving work.",
      },
    },
  },
  "restart-now": {
    admin: {
      title: "Restart now | Cancer Research UK",
      formID: "ONDON000048",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "00MOL0204",
        collection: "UKSF003",
        regular: "00MOL0205",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [10, 20, 30],
        regularNudges: [2, 5, 8],
      },
      donationMotivation: false,
      donationDestination: true,
      header: headerConfig.CRUK,
      introBanner: {
        header: "You are doing something amazing.",
        copy: "Thank you again for your support of Cancer Research UK's life-saving work.",
      },
    },
  },
  "restart-today": {
    admin: {
      title: "Restart today | Cancer Research UK",
      formID: "ONDON000047",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "00MOL0202",
        collection: "UKSF003",
        regular: "00MOL0203",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [10, 20, 30],
        regularNudges: [2, 5, 8],
      },
      donationMotivation: false,
      donationDestination: true,
      header: headerConfig.CRUK,
      introBanner: {
        header: "You are doing something amazing.",
        copy: "Thank you again for your support of Cancer Research UK's life-saving work.",
      },
    },
  },
  "scottish-power-partnership": {
    admin: {
      title: "Scottish Power partnership | Cancer Research UK",
      formID: "ONDON000007",
      product: {
        single: "CP0DON",
        collection: "CP0DON",
      },
      source: {
        single: "UKCP012",
        collection: "UKCP012",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [20, 80, 150],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
    },
  },
  "stand-up-to-cancer": {
    admin: {
      title: "Stand Up To Cancer",
      formID: "ONDON000002",
      product: {
        single: "SUODON",
        collection: "SUOONL",
      },
      source: {
        single: "SCZSUDNWB",
        collection: "N25U4O",
      },
      merchantId: "SU2CSingleDonation",
      emailType: {
        single: Email.SU2C,
      },
    },
    brand: "SU2C",
    themeName: "SU2C",
    theme: su2cTheme,
    components: {
      donationAmount: {
        nudges: [25, 50, 100],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.SU2C,
    },
  },
  "stand-up-to-cancer-channel-4": {
    admin: {
      title: "Stand Up To Cancer Channel 4",
      formID: "ONDON000003",
      product: {
        single: "SUODON",
        collection: "SUOONL",
      },
      source: {
        single: "SCZSUDNWB",
        collection: "N25U4O",
      },
      merchantId: "SU2CSingleDonation",
      emailType: {
        single: Email.SU2C,
      },
    },
    brand: "SU2C",
    themeName: "SU2C",
    theme: su2cTheme,
    components: {
      donationAmount: {},
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.SU2C,
    },
  },
  "stand-up-to-cancer-text": {
    admin: {
      title: "Stand Up To Cancer",
      formID: "ONDON000004",
      product: {
        single: "SUODON",
        collection: "SUOONL",
      },
      source: {
        single: "SCZSUDNWB",
        collection: "N25U4O",
      },
      merchantId: "SU2CSingleDonation",
      emailType: {
        single: Email.SU2C,
      },
    },
    brand: "SU2C",
    themeName: "SU2C",
    theme: su2cTheme,
    components: {
      donationAmount: {},
      donationMotivation: true,
      donationDestination: false,
      header: headerConfig.SU2C,
    },
  },
  "street-fundraiser-1": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000070",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25MSD1001",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "street-fundraiser-2": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000071",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25MSD1002",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "street-fundraiser-3": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000072",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25MSD1003",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "street-fundraiser-4": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000073",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25MSD1004",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "street-fundraiser-5": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000074",
      product: {
        regular: "CRDD",
      },
      source: {
        regular: "25MSD1005",
      },
      emailType: {
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        regularNudges: [8, 15, 30],
      },
      donationMotivation: false,
      donationDestination: false,
      header: headerConfig.CRUK,
      introBanner: {
        header: "Your donation will drive life saving research",
        copy: "The person you are speaking with is a paid fundraiser working directly for Cancer Research UK. Fundraising in this way is effective for us and enables us to commit to long-term programmes of world class research. Thank you once again for your support.",
      },
    },
  },
  "support-today": {
    admin: {
      title: "Support today | Cancer Research UK",
      formID: "ONDON000029",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "24MOW9002",
        collection: "UKSF003",
        regular: "24MOW9001",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {},
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
    },
  },
  "support-us": {
    admin: {
      title: "Support us | Cancer Research UK",
      formID: "ONDON000001",
      product: {
        single: "ONLDON",
        collection: "FUNDON",
        regular: "CRDD",
      },
      source: {
        single: "25MNT1103",
        collection: "UKSF003",
        regular: "25MNT1003",
      },
      merchantId: "CRUKSingleDonation",
      emailType: {
        single: Email.CRUK,
        regular: Email.CRUK_REGULAR,
      },
    },
    brand: "CRUK",
    themeName: "CRUK",
    theme: crukTheme,
    components: {
      donationAmount: {
        nudges: [10, 20, 30],
        regularNudges: [6, 15, 30],
      },
      donationMotivation: true,
      donationDestination: true,
      header: headerConfig.CRUK,
    },
  },
};

export const getFormName = (): string => {
  if (typeof window !== "undefined" && window.location.pathname === "/") {
    return "support-us";
  }
  return typeof window !== "undefined"
    ? config[window.location.pathname.split("/")[1]]
      ? window.location.pathname.split("/")[1]
      : ""
    : "";
};

export const getFormArchetype = (
  formName: Option<string>
): "public" | "face_to_face" =>
  formName?.startsWith("fundraiser-") ? "face_to_face" : "public";

export const getFormAdmin = (formName: string): AdminType =>
  config[formName] ? config[formName].admin : config["support-us"].admin;
export const getFormThemeName = (formName: string): string =>
  config[formName]
    ? config[formName].themeName
    : config["support-us"].themeName;
export const getFormTheme = (formName: string): ThemeType =>
  config[formName] ? config[formName].theme : config["support-us"].theme;
export const getFormBrand = (formName: string): string =>
  config[formName] ? config[formName].brand : config["support-us"].brand;
export const getFormComponents = (formName: string): FormComponentsType =>
  config[formName]
    ? config[formName].components
    : config["support-us"].components;
export const hasNudges = (
  formComponents: FormComponentsType,
  formVersion: string
): boolean => {
  const nudges =
    formVersion === "regular"
      ? formComponents.donationAmount.regularNudges
      : formComponents.donationAmount.nudges;
  return nudges !== undefined && nudges !== null;
};
